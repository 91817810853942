.course-table {
  margin: 8px 0 12px;

  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    border: none;
  }

  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding: 0;
  }

  tr.mat-row,
  tr.mat-header-row {
    height: 38px;
  }
}
