@import '../variables';;

.calendar-wrapper {
  align-items:     center;
  display:         flex;
  height:          95%;
  justify-content: center;
  position:        relative;
  width:           100%;
}

@keyframes calendar-transition-in {
  0% {
    transform: scale(0.8);
    opacity:   0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity:   1;
  }
}

.calendar {
  backface-visibility: hidden;
  height:              160px;
  opacity:             0;
  perspective:         800px;
  perspective-origin:  50% 50%;
  position:            relative;
  -moz-transform:      scale(0.5);
  -ms-transform:       scale(0.5);
  -webkit-transform:   scale(0.5);
  transform:           scale(0.5);
  width:               208px;

  &.in {
    animation:     calendar-transition-in 500ms cubic-bezier(0.735, 0.245, 0.65, 0.985) 1 forwards;
    border-radius: 20px;
    box-shadow:    0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 2px 0 rgba(0, 0, 0, 0.16);
    z-index:       97;

    &:before {
      background:    white;
      border-radius: 50%;
      content:       '';
      display:       block;
      height:        20.87px;
      left:          10px;
      position:      absolute;
      top:           9px;
      width:         22px;
      z-index:       97;
    }

    &:after {
      background:    white;
      border-radius: 50%;
      content:       '';
      display:       block;
      height:        20.87px;
      position:      absolute;
      right:         10px;
      top:           9px;
      width:         22px;
      z-index:       97;
    }
  }

  &:before {
    border-radius: 20px;
    bottom:        0;
    box-shadow:    0 4px 37px -2px rgba(0, 0, 0, 0.4);
    content:       '';
    left:          0;
    position:      absolute;
    right:         0;
    top:           0;
  }
}

.day {
  bottom:          0 !important;
  color:           #FFFFFF !important;
  font-size:       1.5rem !important;
  left:            0 !important;
  position:        absolute !important;
  right:           0 !important;
  text-align:      center;
  top:             0 !important;
  transform-style: preserve-3d;

  .top,
  .bottom {
    backface-visibility: hidden;
    flex-grow:           1;
    height:              100%;
    left:                0;
    margin:              0;
    overflow:            hidden;
    position:            absolute;
    right:               0;
    transform-style:     preserve-3d;

    &.top {
      background-color: $alpha-red !important;
    }

    &.bottom {
      background: linear-gradient(180deg, #EAEAEA 0%, #FFFFFF 100%) !important;
      color:      black;
      font-size:  3rem !important;
    }

    .overlay {
      background-color:           #000000;
      border-bottom-color:        transparent;
      border-bottom-left-radius:  20px;
      border-bottom-right-radius: 20px;
      bottom:                     1px;
      content:                    '';
      left:                       1px;
      opacity:                    0;
      position:                   absolute;
      right:                      1px;
      top:                        0;
    }

    span {
      bottom:      0;
      left:        0;
      line-height: 70px;
      position:    absolute;
      right:       0;
      top:         0;
    }

    .month {
      color:          rgba(#000, 0.87);
      font-size:      3rem;
      letter-spacing: 0.44px;
      line-height:    290px;
    }
  }

  .top {
    border-top-left-radius:  20px;
    border-top-right-radius: 20px;
    font-size:               2.25rem;
    height:                  40%;
    letter-spacing:          0.33px;
    top:                     0;

    span {
      bottom: -100%;
    }
  }

  .bottom {
    border-bottom-left-radius:  20px;
    border-bottom-right-radius: 20px;
    bottom:                     0;
    height:                     60%;

    span {
      top: -100%;
    }
  }

  &:last-child {
    .top,
    .bottom {
      background-color: white;
    }
  }
}
