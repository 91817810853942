@import '../variables';;

.ineffective-feedback-dialog {
  max-height: 432px !important;
  width:      432px !important;

  .mat-dialog-container {
    padding: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: rgba(33, 33, 33, 0.08) !important;
  }

  .mat-button-disabled {
    background: $alpha-gainsboro;
  }

  textarea {
    height: calc(56px + 22.64px);
  }
}
