@import '../variables';

$border-color: #BEBEBE;
$background-color: $alpha-gray-light;
.alpha-card {
  &.mat-card {
    padding: 0;
    display: flex;
    flex-direction: column;
    .mat-card-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 13px 16px;
      min-width: 100%;
      border-bottom: 1px solid $border-color;
      background-color: $background-color;
      border-radius: 4px 4px 0 0;
      min-height: 60px;
      .mat-card-header-text {
        margin: 0;
        padding-left: 0;
        .mat-card-title {
          font-size: 1rem;
          line-height: 24px;
          margin-bottom: 0;
          font-weight: bold;
          .fa-question-circle,
          .fa-info-circle {
            color: $alpha-foreground-hover;
            &:hover {
              color: $alpha-blue;
            }
          }
        }
      }
      .image {
        margin-right: 16px;
      }
      .title {
        margin: 0 0 3px;
        font-size: 0.875rem;
      }
      .description {
        font-size: 0.75rem;
      }
    }
    &.primary {
      .mat-card-header {
        border-top: 4px solid $alpha-blue;
      }
    }
    &.dark {
      .mat-card-header {
        background: #ECECEC;
      }
    }
    &.thin-padding {
      .mat-card-header {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
    &.short-padding {
      .mat-card-header {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    &.course-advisor-history {
      width: 100%;
      min-width: 1350px;
      .mat-card-content {
        position: relative;
        padding: 0;
      }
    }
    .mat-card-footer {
      border-top: 1px solid $border-color;
      background-color: $background-color;
      margin: 0;
      margin-top: auto;
    }
  }
}

.k-card-footer {
  &.save-goal-footer {
    background-color: white;
    border-color: white;
  }
}
