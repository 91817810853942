$alpha-amber-light: #f6e3ba;
$alpha-amber: #ffc10e;
$alpha-black: #212121;
$alpha-blue-dark: #007dd7;
$alpha-blue: #0465bf;
$alpha-blue-light: rgba($alpha-blue, 0.5);
$alpha-blue-extra-light: rgba($alpha-blue, 0.24);
$alpha-blue-dark-bg: #0586ff;
$alpha-border: #bebebe;
$alpha-gray-dark: #a3a3a3;
$alpha-gray-darker: #8b8b8b;
$alpha-gray-light: #fafafa;
$alpha-gray: #dbdbdb;
$alpha-green-dark: #558b2f;
$alpha-green-light: #bfd9c2;
$alpha-green: #8bc34a;
$alpha-orange: #fca238;
$alpha-red-light: #f2d7d8;
$alpha-red: #ff2725;
$alpha-yellow-light: #fdf9d1;
$alpha-gainsboro: #dcdcdc;
$alpha-silver: #c2c2c2;
$kendo-green: #28a745;

$languageColor: #9fcc73;
$scienceColor: #ff8256;
$readingColor: #033b77;

$time-color: #c2568a;
$activity-color: #578cc3;
$progress-color: #8ac256;

$alpha-foreground-hover: rgba(#000, 0.38);
$alpha-foreground: rgba(#000, 0.26);
$alpha-opacity: 0.87;
$alpha-placeholder: 0.6;
$alpha-table-border: rgba(0, 0, 0, 0.24);
$alpha-deep-black: #000000;
$alpha-hover-icon: #1fc3dc;
$alpha-light-black: rgba(0, 0, 0, $alpha-opacity);
$alpha-white: rgba(255, 255, 255, $alpha-opacity);
$alpha-select-color: rgba(0, 0, 0, 0.54);
$alpha-darker-input-bg: rgba(33, 33, 33, 0.08);

$font-family: Roboto, 'Helvetica Neue', sans-serif;
$gutter: 24px;
$header-height: 142px;

$status-on-track: rgba(138, 194, 86, 1);
$status-off-track: rgba(255, 39, 37, 1);
$status-completed: rgba(85, 139, 47, 1);
$status-not-started: rgba(255, 193, 14, 1);
$kendo-default-color: #f56551;
