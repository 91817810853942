@import '../variables';;

.quadrant-table {
  .mat-header-cell {
    border-bottom-color: $alpha-table-border;
    font-size:           .875rem;
    font-weight:         500;
    color:               $alpha-deep-black;
    letter-spacing:      .15px;
    line-height:         16px;
  }

  .mat-cell {
    border-bottom-color: $alpha-table-border;
    font-size:           .875rem;
    color:               $alpha-deep-black;
    line-height:         16px;
    letter-spacing:      .15px;
  }

  .border-left {
    border-left: 1px solid $alpha-table-border;
  }

  .border-right {
    border-right: 1px solid $alpha-table-border;
  }

  td.mat-cell:first-of-type, th.mat-header-cell:first-of-type {
    padding-left: 16px;
  }

  td.mat-cell:last-of-type, th.mat-header-cell:last-of-type {
    padding-right: 16px;
  }

  .mat-header-row:not(:last-of-type) {
    th.mat-header-cell {
      padding-left: 16px;
    }
  }

  //columns width
  .quadrant-column {
    width: 10%;
  }

  .name-column {
    width: 13%;
  }

  .level-column, .grade-column {
    width: 5%;
    padding-left: 10px;
  }

  .rit-score-column,
  .former-rit-score-column,
  .one-year-average-rit-column {
    width: 5%;
    padding-left: 16px;
  }

  .percentile-column,
  .former-percentile-column,
  .one-year-average-percentile-column {
    width: 7%;
    padding-right: 20px;
  }

  .expected-rit-growth-column {
    width: 9%;
    padding-left: 16px;

    &.mat-header-cell {
      text-align: left;
    }

    &.mat-cell {
      text-align: right;

      .expected-rit-growth-column__text {
        max-width: 89px;
      }
    }
  }

  .observed-rit-growth-column {
    width: 12%;
    padding-right: 16px;
  }

  .learning-rate-column {
    width: 10%;
  }

  .percentile-column,
  .former-percentile-column,
  .one-year-average-percentile-column,
  .observed-rit-growth-column,
  .learning-rate-column {
    text-align:    right;

    .mat-sort-header-container {
      justify-content: flex-end;
    }

    .mat-sort-header-arrow {
      position: absolute;
      left:     100%;
    }
  }

  &.no-name {
    .level-column {
      width: 6%;
    }

    .rit-score-column,
    .former-rit-score-column,
    .one-year-average-rit-column {
      width: 7%;
    }
  }
}

.subject-table {
  tr.mat-row:first-of-type {
    .mat-cell {
      border-top: 1px solid $alpha-table-border !important;
    }
  }
}

.quadrant-box {
  width:  24px;
  height: 24px;

  &.one, &.quadrant-box-2 {
    background: $alpha-amber-light;
    border:     1px solid $alpha-table-border;
  }

  &.two, &.quadrant-box-4 {
    background: $alpha-green-light;
    border:     1px solid $alpha-table-border;
  }

  &.three, &.quadrant-box-1 {
    background: $alpha-red-light;
    border:     1px solid $alpha-table-border;
  }

  &.four, &.quadrant-box-3 {
    background: $alpha-yellow-light;
    border:     1px solid $alpha-table-border;
  }
}
