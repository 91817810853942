@import '../variables';

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-success {
  color: $alpha-green;
}

.text-error {
  color: $alpha-red;
}
