@import '../variables';;

.mat-snack-bar-container {
  &.success-snackbar {
    background-color: $alpha-green;

    color:            white;

    .mat-simple-snackbar-action {
      color:       white !important;
      font-weight: 500;
    }

    &--dark {
      background-color: $alpha-black;

      .mat-simple-snackbar-action {
        color: $alpha-blue-dark-bg !important;
      }
    }
  }
}

.alert-snackbar {
  background: $alpha-red;
  color:      white;

  .mat-button-base {
    color: white;
  }
}
