@import '../variables';

// Material.
.mat-tooltip {
  background: rgba($color: black, $alpha: 0.9);
  font-size:  0.75rem;

  &.-no-wrap {
    max-width: none;
  }

  &.tooltip--multiline {
    letter-spacing: .4px;
    //important is required as we need to override the style forcefully.
    max-width:      340px !important;
    white-space:    pre-wrap;
  }
}

// NGB.
.tooltip {
  font-family: $font-family;
  font-size:   0.75rem;

  .arrow {
    display: none;
  }
}

.tooltip--habit-block {
  background-color: black;
  color:            rgba(255, 255, 255, 0.87);
  font-size:        0.75rem;
  font-style:       normal;
  font-weight:      normal;
  letter-spacing:   .4px;
  line-height:      16px;
  max-width:        250px !important;
  padding:          4px 8px;
  white-space:      pre-line;
}
