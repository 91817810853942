.k-dropdownlist {
  color: #495057;
  &.subject-dropdown {
    .k-input-inner {
      background: #FFFFFF;
    }
  }

  &.filter-dropdown {
    .k-input-inner {
      background: #fff;
    }
  }

  &.filter-dropdown-input {
    height: 40px;

    .k-input-inner {
      background: #F5F8FA;
    }
  }
}
