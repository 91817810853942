@import '../variables';

$section-padding: 16px;

.container {
  width: 100%;
  max-width: 1734px;
  padding-left: $gutter;
  padding-right: $gutter;
  margin-right: auto;
  margin-left: auto;

  &.-narrow {
    max-width: 1160px;
  }
}

.space-bottom {
  padding-bottom: 24px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

@media screen and (max-width: 1760px) {
  .container {
    max-width: 1500px;
  }
}

@mixin position-host {
  position: relative;
  top: $header-height - 2 * $section-padding;
  height: calc(100vh - #{$header-height - $section-padding});
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
}
