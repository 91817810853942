.bootcamp-grid {
  &.k-grid {
    tr {
      height: 80px;
    }

    td {
      border: none;

      &:last-of-type {
        width: 70%;

        @media (max-width: 1100px) {
          width: 50%;
        }
      }
    }
  }
}
