.error__container {
  display:         flex;
  align-self:      stretch;
  text-align:      center;
  flex-direction:  column;
  align-items:     center;
  height:          100%;
  justify-content: center;
  padding:         64px;

  .error__img {
    width:         185px;
    margin-bottom: $gutter;
  }

  .error__title {
    margin:      0;
    font-size:   2rem;
    color:       $alpha-blue;
    font-weight: bold;
  }

  div {
    display:         flex;
    flex-direction:  column;
    align-items:     center;
    justify-content: center;

    .error__text {
      width:     450px;
      font-size: 1rem;
    }
  }
}

.error-content--small {
  padding: 0;

  .error__img {
    width: 90px;
    height: 90px;
  }

  div {
    .error__text {
      width:      auto;
      font-size:  .75rem;
    }
  }

  .error__title {
    font-size: 1rem;
  }
}
