@import "../variables";

.mat-form-field-appearance-outline {
  &.mat-form-field-invalid {
    &.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: $alpha-red;
      }
    }
  }

  &.login-input.mat-form-field-disabled {
    .mat-form-field-outline {
      background-color: rgba(0, 0, 0, .1);
    }
  }
}

.mat-form-field {
  &.font-medium {
    font-size: 1rem;
    line-height: 1.5rem;

    .mat-form-field-infix{
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }

    .mat-form-field-flex{
      padding-top: 0.25em;
    }
  }

  &.extra-padding{
    .mat-form-field-flex{
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.custom-prefix{
    .mat-form-field-flex{
      padding-top: 0.125em;
    }

    .mat-form-field-prefix {
      display: none;
    }

    &.mat-form-field-should-float {
      .mat-form-field-prefix {
        display: block;
        opacity: 0.38;
        position: relative;
      }

      .mat-form-field-label-wrapper{
        letter-spacing: 0.4px;
        margin-left: -8px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance:textfield;
    }
  }

  &.darker-background {
    .mat-form-field-flex{
      background-color: $alpha-darker-input-bg;
    }

    &.mat-form-field-should-float:not(.mat-focused){
      .mat-form-field-label-wrapper{
        .mat-form-field-label {
          color: rgba($color: #000, $alpha: 0.38);
        }
      }
    }
  }

  &.-no-space-top {
    .mat-form-field-infix {
      border-top: none;
      padding: 0;
    }
  }

  &.dense {
    .mat-form-field-wrapper {
      margin-bottom: -1.25em;

      .mat-form-field-infix {
        border-top: none;
        padding: 0.131rem 0 0.656rem 0;

        .mat-select-arrow-wrapper {
          display: flex;
        }
      }
    }
  }
}
