@import '../variables';

.mat-form-field {
  &.mat-form-field-small {
    height:    29px;
    width:     170px;
    font-size: 0.625rem;

    .mat-form-field-flex {
      padding: 0 10px;
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }
  }

  &.mat-form-field-medium {
    height:    40px;
    width:     226px;
    padding:   0 10px;
    font-size: 0.875rem;

    .mat-form-field-flex {
      height:  40px;
      padding: 0 10px;

      .mat-form-field-infix {
        border-top-width: 8px;
      }
    }

    .mat-select-arrow-wrapper {
      transform: unset;
    }
  }

  .mat-select-arrow {
    color: rgba(0, 0, 0, $alpha-placeholder);
  }

  .mat-select-value {
    color: $alpha-select-color;
  }

  &-appearance-fill {
    .mat-form-field-underline::before {
      background-color: rgba(0, 0, 0, $alpha-placeholder);
    }
  }
}

.mat-select-panel {
  &.mat-select-panel-long {
    max-height: 512px;
  }
}
