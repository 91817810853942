@import '../variables';;

table {
  width: 100%;
}

tr.history-element-row {
  height: 40px !important;
}

tr.history-detail-row {
  height: 0 !important;
  background: #f6f6f6;
  padding: 24px 16px;
}

tr.history-expanded-row {
  background: $alpha-blue-extra-light;
}

.mat-column-expandedDetail {
  padding-right: 0 !important;
}

.history-element-detail {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-left: 16px;
}

tr.history-element-row:not(.example-expanded-row):hover {
  background: $alpha-blue-extra-light;
}

tr.history-element-row:not(.history-detail-row):active {
  background: $alpha-blue-extra-light;
}

th.history-element.mat-header-cell:last-of-type {
  text-align:    right;
  padding-right: 16px !important;
}

td.history-element:last-of-type {
  text-align:    right;
  padding-right: 16px !important;
}

th.history-element.mat-header-cell:first-of-type {
  padding-left: 16px !important;
}

td.history-element:first-of-type {
  padding-left: 16px !important;
}
