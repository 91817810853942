@import '../variables';

.ng5-slider {
  span.ng5-slider-ticks {
    top: -8px;
    .ng5-slider-tick {
      background: none;
      &.ng5-slider-selected {
        background: none;
      }
      .ng5-slider-tick-legend {
        font-size: .75rem;
        font-family: $font-family;
        white-space: nowrap;
      }
      .ng5-slider-inner-tooltip {
        display: none;
      }
    }
  }
  .ng5-slider-span {
    .ng5-slider-bar {
      height: 2px;
      background: $alpha-blue-extra-light;
      &.ng5-slider-selection {
        background: $alpha-blue;
      }
    }
    &.ng5-slider-pointer {
      width: 20px;
      height: 20px;
      background-color: $alpha-blue;
      transform: scale(0.7);
      transform-origin: center;
      transition: transform 0.2s;
      &:after {
        display: none;
      }
      &.ng5-slider-active {
        transform: scale(1);
      }
    }
    &.ng5-slider-bar-wrapper {
      height: 23px;
      padding-top: 11px;
    }
  }
}

.mat-slider {
  &.mat-primary {
    .mat-slider-track-background {
      background: $alpha-blue-extra-light;
    }
  }
}

.alpha-slider {
  &.-inverted-colors {
    .mat-slider-track-background {
      background: $alpha-blue;
    }
    .mat-slider-track-fill {
      background: $alpha-blue-extra-light;
    }
  }
  &:hover {
    .mat-slider-track-background {
      background: $alpha-blue;
    }
    .mat-slider-track-fill {
      background: $alpha-blue-extra-light;
    }
  }
}

.alpha-slider {
    .k-slider-selection, .k-draghandle {
      background-color: $alpha-blue !important;
    }
    .k-tick{
      &:not(.k-tick-large) {
        display: none !important;
      }
    }
}
