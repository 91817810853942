@import '../variables';

.mat-button-toggle-group {
  box-shadow:    none;
  border-radius: 4px !important;
  border:        1px solid $alpha-blue;

  .mat-button-toggle {
    height:      33px;
    font-weight: 500;
    font-size:   0.875rem;
    line-height: 16px;
    color:       $alpha-blue;
    background:  transparent;
    border-left: 1px solid $alpha-blue;

    &.mat-button-toggle-checked {
      color:            white;
      background-color: $alpha-blue;
      border-bottom:    1px solid $alpha-blue;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;

      &.mat-button-toggle-checked {
        border-right: 1px solid $alpha-blue;
      }
    }

    .mat-button-toggle-button {
      border: none;

      .mat-button-toggle-label-content {
        line-height:    33px;
        text-transform: uppercase;
      }
    }
  }

  &.button-toggle--effectiveness {
    .mat-button-toggle {
      height: auto;

      button {
        height: 36px;
      }
    }
  }

  &.-disabled {
    pointer-events: none;
    border:         1px solid $alpha-gray;

    .mat-button-toggle {
      color:       $alpha-gray-darker;
      border-left: none;

      &-checked {
        background-color: $alpha-gray;
        border-left:      1px solid $alpha-gray;
        border-bottom:    1px solid $alpha-gray;
        color:            $alpha-gray-darker;
      }

      &:last-child {
        &.mat-button-toggle-checked {
          border-right: 1px solid $alpha-gray;
        }
      }
    }
  }
}
