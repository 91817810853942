@import '../variables';;

$max-height-reward: calc(100vh - 234px);
$max-height-cash-register: calc(100vh - 241px);
$max-height-cash-register-small: calc(100vh - 298px);
$paginated-container: calc(100vh - 293px);

.transaction-card__content.mat-card-content {
  margin-bottom: 0;

  .loader-container {
    height: $max-height-reward;

    &--paginated {
      height:     $paginated-container;
      max-height: $paginated-container;
    }

  }

  .empty {
    height:          $max-height-reward;
    display:         flex;
    align-items:     center;
    justify-content: center;

    &__block {
      padding: 0;
    }
  }
}

.transaction-table {
  max-height: $max-height-reward;
  overflow:   auto;

  &--paginated {
    height:     $paginated-container;
    max-height: $paginated-container;
  }

  .alpha-table {
    .mat-cell {
      line-height:    16px;
      color:          $alpha-deep-black;
      letter-spacing: 0.0015em;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .mat-header-cell {
      &:first-of-type {
        width: 25%;
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    .mat-column-merchant {
      width: 25%;
    }

    .mat-column-details {
      width: 40%;
    }
  }
}

.cash-register {
  .transaction-table {
    max-height: $max-height-cash-register;

    &--paginated {
      max-height: $max-height-cash-register-small;
      height:     $max-height-cash-register-small;
    }

    .alpha-table {
      .mat-column-amount {
        width: 13%;
      }

      .mat-column-date {
        width: 20%;
      }

      .mat-column-merchant {
        width: 14%;
      }
    }
  }

  .transaction-card__content {
    .loader-container {
      height: $max-height-cash-register;

      &--paginated {
        max-height: $max-height-cash-register-small;
      }

    }

    .empty {
      height: $max-height-cash-register;

      &--paginated {
        max-height: $max-height-cash-register-small;
      }

    }
  }
}

.transaction-card {
  .mat-paginator, .mat-paginator-page-size .mat-select-trigger {
    color: rgba(0, 0, 0, $alpha-opacity);
    font-size: .875rem;
  }

  .mat-icon-button {
    color: rgba(0, 0, 0, $alpha-placeholder);
  }

  .mat-paginator-page-size-label {
    display: none;
  }

  .mat-paginator-container {
    padding-left: 0;
  }

  .paginator-label {
    color:     rgba(0, 0, 0, $alpha-opacity);
    font-size: 0.875rem;
  }

  &__header {
    .mat-card-header-text {
      width: 100%;

      .new-btn {
        height: 36px;
      }
    }
  }
}
