.sub-header__container {
  padding: 16px 0;
  user-select: none;
  .title {
    margin-right: 16px;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 37px;
  }
  .beta-icon {
    margin-left: 0.5rem;
  }
  .title,
  .selectors {
    flex-shrink: 0;
  }
  .selectors {
    font-size: 0.875rem;
    .mat-form-field-flex {
      padding: 0.5em 0.85em 0 1em;
    }
    .mat-form-field {
      .mat-form-field-wrapper {
        .mat-form-field-infix {
          line-height: 24px;
          padding-bottom: 0.4em;
        }
      }
    }
  }
}
