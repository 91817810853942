@import '../variables';;

.mat-raised-button {
  &.primary {
    height:          29px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    font-size:       0.894rem;
    font-weight:     500;
    letter-spacing:  1.25px;
    line-height:     16px;
    text-align:      center;
    color:           $alpha-gray-light;
    background:      $alpha-blue;

    .mat-button-wrapper {
      display:     flex;
      align-items: center;

      .mat-spinner {
        margin-left: 5px;

        circle {
          stroke: $alpha-gray-light;
        }
      }
    }
  }

  &.secondary {
    height:           29px;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    font-size:        0.894rem;
    font-weight:      500;
    line-height:      16px;
    text-align:       center;
    letter-spacing:   1.25px;
    color:            $alpha-blue;
    border:           1px solid rgba(0, 0, 0, 0.12);
    border-radius:    4px;
    background-color: rgba(98, 2, 238, 0);
    box-sizing:       border-box;
    box-shadow:       none;

    &:not([disabled]):active:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    &.cdk-program-focused {
      .mat-button-focus-overlay {
        opacity: 0;
      }
    }
  }

  &.-vertical {
    height: auto;
    min-width: 0;
    display: flex;
    justify-content: flex-start;
    padding: 16px 8px;

    .mat-button-wrapper {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
  }
}

.mat-menu-item {
  &.-active {
    font-weight: bold;
  }
}

.button-link {
  padding:         0;
  font-family:     $font-family;
  font-size:       0.875rem;
  text-decoration: underline;
  color:           $alpha-blue;
  background:      transparent;
  border:          none;
}

.mat-menu-trigger {
  &.-with-icon {
    .mat-button-wrapper {
      flex:            1 1 0px;
      display:         flex;
      justify-content: space-between;
      align-items:     center;
    }
  }
}

.alpha-btn {
  &.k-selected,&.primary {
    background-color: $alpha-blue;
  }
}
