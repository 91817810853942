.summary-table, .daily-plan-table {
  table {
    height: 100%;

    tr {
      height: auto;

      td {
        &:first-of-type {
          width: 68px !important;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    th.mat-header-cell {
      &:first-of-type {
        height: 68px !important;
        width:  68px !important;
      }
    }
  }
}
