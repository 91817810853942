@import "../variables";

.subject-image {
  border-radius: 3px;
}

.kendo-green {
  color: $kendo-green;
}

.error,
.loading {
  height: 316px;
}

.map-summary-grid {
  border-width: 0;
  .k-grid-content.k-virtual-content,
  .k-grid-header {
    padding: 0 !important;
  }
  .k-grid-content.k-virtual-content {
    overflow: hidden;
  }
  tr:not(:first-child) {
    border-bottom: 1px solid black;
  }

  .disabled-row {
    td:not(:last-child),
    span {
      opacity: 0.6;
    }
  }
}
