@import '../variables';;

@mixin learningTable {
  th.mat-header-cell, td.mat-cell {
    padding:       0;
    height:        67px;
    width:         auto;
    border-bottom: none;

    .col-container {
      padding: 0;
      width:   100%;
      display: block;

      > div {
        height: 100%;
      }

      .subject-image {
        margin-top: -3px;
      }

      .progress-value {
        line-height: 24px;
        font-size: .875rem;
        color: rgba(0,0,0,.6);
        letter-spacing: .1px;
      }
    }

    &:nth-child(1) {
      width: 61px;
    }

    &:last-child {
      width: auto;

      .col-container {
        .progress-value {
          color: #000;
        }
      }
    }
  }
}

@mixin daily-learning-table {
  .daily-learning-table {
    display: block;
    height:  100%;

    .alpha-table {
      background:   transparent;
      table-layout: fixed;

      @include learningTable;

      th.mat-header-cell {
        color: $alpha-deep-black;

        .col-container {
          align-items: center;
        }
      }

      tr.mat-footer-row td.mat-footer-cell {
        padding-left: 0;
        height:       67px;
        border:       none;

        &:first-of-type {
          font-weight: normal;
        }

        .col-container {
          padding: 0;
        }
      }
    }
  }
}

.daily-learning-plan__card {
  @include daily-learning-table;

  tr:first-of-type td.mat-cell {
    border-top: 1px solid rgba(0, 0, 0, .24);
  }
}

.summary-table {
  @include daily-learning-table;
}
