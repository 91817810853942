.k-card {
  &.practice-schedule__card {
    border-radius: .5rem;

    .k-card-header {
      background: #fff;
      border-bottom-width: 0;
      height: 62px;
    }

    .k-card-footer {
      background: #fff;
    }
  }

  /** TODO - PROCESS-100: Remove Sub-standard component style override **/
  &.leaderboard-card {
    border-radius: .5rem;

    .k-card-header {
      background: #fff;
      height: 62px;
    }

  }
}
