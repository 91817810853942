@import '../variables';;

$form-background: #FCFDFF;

.auth-container {
  min-height:       100vh;
  display:          flex;
  flex-direction:   column;
  flex:             1;
  background-size:  cover;
  justify-content:  center;
  position:         relative;
  align-items:      center;
  background-color: $alpha-gray-light;

  mat-error {
    font-size: 0.75rem;
  }

  .mat-spinner {
    margin-left: 8px;
  }

  .mat-progress-spinner circle {
    stroke: white;
  }

  .mat-button-wrapper {
    width:           100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
  }

  input {
    color:          rgba(#000, 0.87);
    font-size:      1.022rem;
    letter-spacing: 0.15px;
  }

  mat-label {
    color:          rgba(#000, 0.87);
    font-size:      1.022rem;
    letter-spacing: 0.15px;
  }

  .success-snackbar {
    background-color: $alpha-green;
    color:            white;

    .mat-simple-snackbar-action {
      color: white;
    }
  }

  .left {
    width:           50%;
    background-size: cover;
  }

  .right {
    width:           50%;
    background:      $form-background;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
  }

  .login-wrapper {
    max-width:   calc(100vw - 20px);
    display:     flex;
    align-items: center;
    flex-grow:   1;
    flex-shrink: 1;
  }

  .login {
    width:            1222px;
    border-radius:    2px;
    background-color: $form-background;
    display:          flex;

    .login-form-container {
      background:    $form-background;
      border-radius: 0 0 3px 3px;
      display:       block;
      padding:       0 74px;

      .login-form {
        display:        flex;
        flex-direction: column;
        width:          100%;
        max-width:      calc(100vw - 120px);
        min-width:      200px;
        color:          #989BA1;
        font-size:      0.875rem;
      }
    }
  }

  .subtitle {
    color:          #000;
    font-size:      .75rem;
    letter-spacing: 0.21px;
    line-height:    20px;
    font-weight:    normal;
    text-align:     center;
  }

  .first-input {
    margin-top:    8px;
    margin-bottom: 42px;
  }

  .actions {
    width:           100%;
    height:          73px;
    display:         flex;
    justify-content: space-between;
    align-items:     center;

    a {
      font-size:      0.875rem;
      letter-spacing: 0.1px;
      line-height:    24px;
    }
  }

  .title {
    color:          rgba(#000, 0.87);
    font-size:      1rem;
    font-weight:    bold;
    letter-spacing: 0.15px;
    margin-top:     10px;
    text-align:     center;
  }

  .alert-text {
    opacity:        0;
    font-size:      0.875rem;
    font-weight:    normal;
    font-style:     normal;
    font-stretch:   normal;
    line-height:    1.14;
    letter-spacing: 1.25px;
    text-align:     center;
    color:          $alpha-red;
    margin:         8px 0 0 0;
    transition:     0.3s;
    height:         15px;

    &--show {
      opacity:    1;
      transition: 0.3s;
    }
  }

  .welcome {
    padding:         50px;
    display:         flex;
    flex-direction:  column;
    justify-content: center;

    .welcome-image {
      display:         flex;
      justify-content: center;
      margin-top:      4px;
    }

    .welcome-text {
      display:         flex;
      justify-content: center;
      align-items:     center;
      flex-direction:  column;
      margin-top:      24px;

      .welcome-title {
        color:          rgba(#fff, 0.87);
        font-size:      1.5rem;
        font-weight:    bold;
        letter-spacing: 0.22px;
        line-height:    28px;
        text-align:     center;
      }

      .welcome-subtitle {
        color:          #FFF;
        max-width:      382px;
        font-size:      .75rem;
        letter-spacing: 0.21px;
        line-height:    20px;
        text-align:     center;
        margin-top:     23px;
      }
    }
  }

  .logo {
    height: 100%;
    margin: 0 7px;
  }

  .login-button {
    height:           43px;
    width:            169px;
    border-radius:    4px;
    background-color: $alpha-blue;
    display:          flex;
    color:            rgba(#fff, 0.87);
    font-size:        1rem;
    font-weight:      bold;
    letter-spacing:   0.15px;
    line-height:      24px;
    text-align:       center;
    align-items:      center;
    justify-content:  center;
  }

  .mat-raised-button[disabled] {
    background: rgba($alpha-blue, 0.26);
    color:      $alpha-gray-light;
  }
}
