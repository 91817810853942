.wrapper {
  margin:    0 auto;
  max-width: 1736px;
}

.chart-wrap {
  height:  calc(100vh - 236px);
  padding: 13px 0;
}

.error-content {
  align-items:     center;
  display:         flex;
  height:          100%;
  justify-content: center;
}
