@import '../variables';;

$first-col-width:   104px;
$common-cell-width: 152px;

@mixin table-col {
  &.goal-tracker-col__description {
    width: auto;

    .description {
      height:      100%;
      padding-top: 16px;
    }
  }

  &:first-of-type {
    border-right: 2px solid $alpha-border;
    padding-left: 0;
    width:        $first-col-width;
    background:   $alpha-gray-light;
  }

  &:last-of-type {
    padding-right: 0;
  }

  .image-column {
    height:   100%;
    width:    100%;
    position: relative;

    .main-image {
      margin-bottom: 4px;
    }

    .overwritten-text {
      color:          rgba(0, 0, 0, 0.87);
      text-transform: capitalize;
      letter-spacing: initial;
      width:          70px;
      font-size:      0.75rem;
      position:       absolute;
      top:            -8px;
      left:           -16px;
      background:     rgba(0, 0, 0, 0.08);
      border-radius:  0 20px 20px 0;
      padding:        1px 0;
    }
  }
}

@mixin table-col-header {
  text-align:    center;
  font-weight:   500;
  border-bottom: 2px solid $alpha-border;

  &.goal-tracker-col__description {
    width: auto;
  }

  &:first-of-type {
    padding-left: 0;
    width:        $first-col-width;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

@mixin table-footer {
  tr.mat-header-row, tr.mat-footer-row {
    background: $alpha-gray-light;
  }
}

td.mat-footer-cell {

  &:first-of-type {
    border-right: 1px solid rgba(0, 0, 0, .12);
    padding-left: 0 !important;
    width:        $first-col-width;
  }

  &:last-of-type {
    padding-right: 24px;
  }
}

.daily-table .alpha-table {
  table-layout: fixed;

  td.mat-cell {
    height: 196px;
    width:  auto;
    @include table-col;
  }

  th.mat-header-cell {
    width: auto;
    @include table-col-header;
  }

  @include table-footer;

  .mat-footer-cell {
    &:last-of-type {
      padding-right: 0;
    }
  }
}

.goal-tracker-table {
  .alpha-table {
    table-layout: fixed;

    td.mat-cell {
      height: 152px;
      width:  $common-cell-width;

      @include table-col;
    }

    th.mat-header-cell {
      width: $common-cell-width;
      @include table-col-header;
    }

    @include table-footer;
  }
}

.goal-tracker-col {
  &__app-logo {
    background-position: center;
    background-repeat:   no-repeat;
    height:              36px;
    margin-bottom:       4px;
    width:               36px;
  }

  &__app-name {
    color:          black;
    font-size:      0.75rem;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    text-align: center;

    &__is-overwritten {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  &--active {
    color: rgba($alpha-blue, .87);
  }
}
