.practice-schedule__grid {
  .k-grid-toolbar {
    background: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 62px;
    padding: 0 16px;
  }
  tbody tr {
    height: 68px;
  }
}

.learning-rings {
  &.k-grid {
    .k-grid-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 16px;
      .learning-rings__title {
        font-size: 1.25rem;
      }
    }

    .k-grid-footer {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    th,
    td {
      &:not(:first-child) {
        border-left: 0px;
      }
      &:first-child {
        border-right-width: 1px;
      }
    }
  }
}

.learning-rings,
.practice-schedule__grid {
  &.k-grid {
    border-radius: 8px;
    tr {
      background-color: unset;
      &:not(:last-child) {
        td {
          border-bottom-width: 1px;
        }
      }
      &:not(.k-detail-row):hover,
      &:not(.k-detail-row).k-state-hover,
      &.k-state-selected > td {
        background-color: unset;
      }
    }
  }
}
