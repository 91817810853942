.alpha-table {
  tr.mat-row {
    height: 51px;
  }

  .mat-header-cell {
    font-size:   0.875rem;
    line-height: 20px;
    color:       rgba(black, 0.87);
    font-weight: bold;

    &:first-of-type {
      padding-left: 16px;
    }

    &:last-of-type {
      padding-right: 16px;
    }
  }

  .mat-cell {
    font-size:   0.875rem;
    line-height: 20px;

    &.-expanded {
      padding-left: 20px;
      border-left:  4px solid $alpha-blue;
    }

    &:first-of-type {
      padding-left: 16px;
    }

    &:last-of-type {
      padding-right: 16px;
    }
  }

  tr.expandable-row {
    background: white;
    cursor:     pointer;

    .mat-cell {
      border-bottom-width: 0;
    }

    &--disabled {
      cursor: not-allowed;

      > .mat-cell {
        border-bottom: 0;
      }
    }

    &.expanded-row {
      .mat-cell {
        border-bottom-width: 0;

        &:first-child {
          border-left:  4px solid $alpha-blue;
          padding-left: 12px;
        }
      }

      .expanded-detail-row {
        .mat-cell {
          border-bottom-width: 1px;
          padding: 0 16px;
        }
      }
    }
  }

  tr.expanded-detail-row {
    height: 0 !important;

    .mat-column-expandedDetail {
      &.expanded {
        border-bottom: 0;
      }
    }

    .mat-cell {
      border-bottom-width: 1px;

      &.expanded {
        border-top-width: 0;

        .mat-cell {
          border-top-width: 0;
        }
      }
    }
  }

  .expander {
    transition: transform 0.2s ease-out;

    &.-rotated {
      transform: rotate(90deg);
    }
  }

  td.mat-footer-cell.mat-column-pagination {
    height:  56px;
    border:  none;
    padding: 0 16px;
  }
}
