@import '~@angular/cdk/overlay-prebuilt.css';
@import './alpha-theme';
@import '~@progress/kendo-theme-bootstrap/dist/all.scss';
@import './app/styles/main';
@import './app/styles/index/index';

html,
body {
  font-family: $font-family;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  height: 100%;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

input[type='text'],
button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

.k-notification-group {
  z-index: 999;
  .custom-notification {
    top: 57px;
  }
}

.k-tooltip-wrapper.standard-tooltip {
  z-index: 999;
}

.k-tooltip-wrapper.covid-learning-loss-tooltip {
  * {
    font-family: $font-family;
  }
  img {
    object-fit: contain;
    height: 16px;
    width: 16px;
  }
  z-index: 999;
  .k-tooltip {
    background-color: white;
    color: black;
    padding: 0;
    .k-callout {
      color: white;
    }
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
      0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.custom-notification-covid {
  max-width: 550px;
}

.custom-notification-report {
  top: 60px;
}

.br-8 {
  border-radius: 8px;
}
