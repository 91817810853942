$tooltip-background-color: rgba(black, 0.9);

.alpha-tooltip {
  position:      relative;
  padding:       1px 10px;
  font-size:     0.75rem;
  color:         white;
  background:    $tooltip-background-color;
  border-radius: 3px;

  &__container {
    padding: 10px 0;
  }

  > .arrow {
    position:     absolute;
    left:         50%;
    bottom:       -7px;
    width:        0;
    height:       0;
    border-left:  7px solid transparent;
    border-right: 7px solid transparent;
    border-top:   7px solid $tooltip-background-color;
    transform:    translateX(-50%);
  }

  &.rings-tooltip {
    padding: 4px 10px;
  }
}

.daily-tooltip {
  &.tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: unset;
    min-width: 215px;
    width: auto;
    opacity: 1;
  }
}
