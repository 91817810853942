.cb-student-registration-form {
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;

  .form-title {
    color: #231F20;
    font-weight: 500;
    margin-block-start: 0.75em;
    margin-block-end: 0.75em;
  }

  .k-form-buttons {
    margin: 16px 0;
  }

  .k-button-solid-primary {
    background: #F56551;
    border-color: #F56551;

    &:focus {
      box-shadow: 0 0 0px .25rem rgba(255, 122, 89, .5);
    }
  }

  .k-form-error {
    color: #f2545b;
  }

  .k-form {
    .k-form-fieldset {
      margin: 0;
      padding-top: 16px;
    }

    .k-form-field {
      margin-top: 0;
      min-height: 86px;
    }
  }

  .disclaimer {
    color: #555555;
    font-size: 1.063rem;
    font-weight: 300;
    text-align: center;

    a {
      color: #777777;

      :focus {
        color: #777777
      }
    }
  }

  .form-label {
    font-size: .875rem;
    color: #251f20;
    margin-bottom: 4px;

    a {
      color: #777777;

      &:hover {
        color: #777777;
      }
    }
  }

  .k-textbox {
    padding: 0 15px;
    color: #33475b;
    font-size: 1rem;
    line-height: 22px;
  }

  .k-textbox .k-input-inner, .k-input {
    box-shadow: none;
    background: #f5f8fa;
    border-radius: 3px;
    height: 40px;

    &:focus, &:active {
      box-shadow: none !important;
    }

    &:not(:focus-within) {
      border: 1px solid #cbd6e2;
    }
  }
}

@media screen and (max-width: 600px) {
  kendo-dialog {
    .k-dialog {
      max-width: 350px;
    }
  }
}
